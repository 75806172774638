body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
}
*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    box-sizing: border-box;
}
:root{
    --orange:#FF7425;
    --white: #ffffff;
    --light-black: #0B0B0B;
    --brown: #745236;
    --black: #000000;
    --light-grey: #EDEDED;
    --dark-grey: #8E8E8E;
    --dark-orange:#F6612F;
}
a:hover , a{
    text-decoration: none!important;
}
a:focus{
    outline: none;
}
.container{
    max-width: 85%!important;
    margin: 0 auto;
}
.container-fluid{
    padding-left: 0!important;
    padding-right: 0!important;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0!important;
    margin-bottom: 0!important;
}
h1{
    font-style: italic;
    font-weight: 600!important;
    font-size: 150px!important;
    line-height: 150px!important;
    color: var(--orange);
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}
h2{
    font-style: normal;
    font-weight: 700!important;
    font-size: 70px!important;
    line-height: 70px!important;
    color: var(--white);
    text-transform: uppercase;
    max-width: max-content;
    position: absolute;
    z-index: 1;
}
h3{
    font-style: normal;
    font-weight: 700!important;
    font-size: 60px!important;
    line-height: 70px!important;
    text-transform: uppercase;
}
h3.white{
    color: var(--white);
}
h3.orange{
    color: var(--orange);
}
h5{
    font-style: normal;
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 18px!important;
    text-transform: uppercase;
    width: 100%;
    max-width: max-content;
    margin-bottom: 14px!important;
}
h5.grey{
    color: var(--black);
}
h5.orange{
    color: var(--black);
}
p{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--white);
}
.main-header{
    width: 100%;
}
.header{
    background-color: var(--light-black);
    padding-top: 26px;
    padding-bottom: 4px;
    position: relative;
    z-index: 999;
}
.main-logo a.logo-link{
    background-color: var(--white);
    width: 100%;
    display: inline-block;
    max-width: 324px;
    height: 135px;
    text-align: right;
    border-radius: 0 120px 120px 0;
}
.main-logo a.logo-link img{
    width: 100%;
    max-width: 150px;
    margin-right: 50px;
}
ul.menu-bar-items{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    max-width: max-content;
    margin-left: auto;
    padding: 13px 50px;
    border-radius: 100px;
    margin-bottom: 0;
}
.header > .row{
    align-items: center;
}
ul.menu-bar-items li a img{
    transition: all 0.3s ease-in-out;
}
ul.menu-bar-items li a:hover{
    background-color: var(--orange);
    padding: 11px;
    border-radius: 50%;
    border: 1px solid var(--orange);
    transition: all 0.3s ease-in-out;
}
ul.menu-bar-items li a:hover img{
    filter: brightness(0) invert(1);
    transition: all 0.3s ease-in-out;
}
ul.menu-bar-items li img{
    max-width: 35px;
}
ul.menu-bar-items li a{
    padding: 11px;
    transition: all 0.3s ease-in-out;
    display: flex;
    border-radius: 50%;
    border: 1px solid transparent;
    background-color: transparent;
    margin: 0 4px;
    position: relative;
}
ul.menu-bar-items li a::before {
    content: 'Home';
    position: absolute;
    display: block;
    height: 32px;
    top: auto;
    background-color: var(--black);
    color: #fff;
    text-transform: capitalize;
    z-index: 0;
    font-weight: 500;
    font-size: 13px;
    padding: 7px 15px;
    width: max-content;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.22);
    letter-spacing: -.24px;
    bottom: 23px;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    transform: translateX(0px);
    left: 0%;
    margin-left: -5px;
}
ul.menu-bar-items li a:hover::before {
    transform: translateY(-34px);
    transition: all 0.4s ease-in-out;
    opacity: 1;
    visibility: visible;
}
.banner-section{
    background-color: var(--light-black);
    width: 100%;
    margin-top: -3px;
    padding-bottom: 40px;
    overflow: hidden;
    position: relative;
}
.hero-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.banner-right p{
    position: relative;
    border-radius: 500px 0px 0px 500px;
}
.banner-right img{
    border-radius: 500px 0px 0px 500px;
    width: 100%;
    height: 640px;
    object-fit: cover;
}
.banner-right p::before{
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 116, 37, 0.4);
    border-radius: 500px 0px 0px 500px;
}
ul.menu-bar-items li a.about::before {
    content: 'About';
}
ul.menu-bar-items li a.services::before {
    content: 'Services';
}
ul.menu-bar-items li a.contact::before {
    content: 'Contact';
}
ul.menu-bar-items li a.menu::before {
    content: 'Menu';
}
#root{
    overflow-x: hidden;
}
.banner-section .slick-dots{
    bottom: auto;
    top: 40%;
    left: 42%;
    right: 0;
    transform: rotate(90deg);
}
.banner-section .slick-dots li{
    width: 100px;
    height: 8px;
    background-color: var(--white);
    margin: -1px;
}
.banner-section .slick-dots li button:before{display: none;}
.banner-section .slick-dots li.slick-active{
    background-color: var(--orange);
}
.banner-section .slick-dots li:first-child{
    border-radius: 50px 0 0 50px;
}
.banner-section .slick-dots li:last-child{
    border-radius: 0 50px 50px 0;
}
.banner-section .slick-dots li button{
    width: 100px;
    height: 8px;
}
.banner-section .slick-slider .slick-list{border-radius: 500px 0px 0px 500px;}
.top-banner-head p{
    position: absolute;
    top: 0;
    right: -300%;
    max-width: 600px;
    text-shadow: 0px 0px 15px #000;
    padding-left: 55%;
    font-size: 17px;
}
.top-banner-head{
    position: relative;
    padding-left: 75%;
    z-index: 1;
}
.banner-section .slick-slider{
    max-width: 1400px;
}
.banner-section .main-title{
    padding-left: 75%;
    margin-top: 85px!important;
    margin-bottom: 14px!important;
}
.orange-banner-bg{
    padding-top: 15px;
}
h1{
    position: absolute;
    z-index: 1;
    text-transform: uppercase;
    margin-top: 90px!important;
}
.all-section{
    padding: 100px 0;
}
.bg-orange{
    background-color: var(--dark-orange);
}
.bg-light-black{
    background-color: var(--light-black);
}
.link {
  width: 7rem;
  height: auto;
  display: inline-block;
  font: 300 1.55rem/1.4 "Josefin Sans";
  text-transform: uppercase;
  letter-spacing: 0.1175em;
  word-spacing: 0.5em;
  text-decoration: none;
}
.link__svg {
  width: 100%;
  height: auto;
  transform-box: fill-box;
  fill: var(--white);
  stroke: var(--white);
  stroke-width: 0.05em;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.link__cloud {
  transform-origin: 50% 50%;
  -webkit-animation: rotate normal infinite 60s linear;
          animation: rotate normal infinite 60s linear;
  fill: rgba(225, 225, 225, 0.15);
}
.link__face, .link__arrow {
  transform-origin: 50% 50%;
  transition: transform 0.15s cubic-bezier(0.32, 0, 0.67, 0);
}
.link:hover .link__face, .link:hover .link__arrow {
  transform: scale(1.1);
  transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}
.link__arrow {
  stroke-width: 0.075em;
}
.link__text {
  -webkit-animation: rotateReverse normal infinite 20s linear;
   animation: rotateReverse normal infinite 20s linear;
  transform-origin: 50% 50%;
}
.link:hover .link__text {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.link--alt {
  font-size: 25PX;
  letter-spacing: 0;
  word-spacing: 0;
}

@-webkit-keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateReverse {
  to {
    transform: rotate(-360deg);
  }
}
@keyframes rotateReverse {
  to {
    transform: rotate(-360deg);
  }
}
.Button{
    padding-top: 25px;
}
.banner-section .Button{
    padding-top: 265px;
}
.Social-media-names{
    position: absolute;
    bottom: 45%;
    top: auto;
    left: -5%;
    transform: rotate(-90deg);
}
.Social-media-names li{
    padding-right: 55px;
}
.Social-media-names li a{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: var(--white);
    transition: all 0.3s ease-in-out;
}
.Social-media-names li a:hover{
    color: var(--dark-orange);
    transition: all 0.3s ease-in-out;
}
.team-section h3{
    max-width: 800px;
    width: 100%;
}
.footer {
    padding-top: 135px;
    padding-bottom: 20px;
}
.footer .top-footer-content{
    border-top: 1px solid var(--white);
    padding-bottom: 55px;
}
.footer a , .footer li{
    color: var(--white);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    transition: all 0.3s ease-in-out;
}
.footer a:hover {
    transition: all 0.3s ease-in-out;
    color: var(--orange);
    padding-left: 5px;
}
.footer .footer-block-1 a:hover {
    padding-right: 5px;
    padding-left: 0;
}
.footer-block-1 ul{
    text-align: right;
}
.footer-block-2{
    background-color: var(--light-black);
    margin-top: -80px;
}
.newsletter h4{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.42em;
    color: var(--white);
    padding-top: 150px;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 20px!important;
}
.newsletter h4::before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    background-color: var(--white);
    height: 118px;
    width: 1px;
    margin-top: 23px;
}
.social-icons{
    margin-top: 30px;
}
.social-icons li{
    padding: 0 25px;
    transition: all 0.3s ease-in-out;
}
ul.footer-links{
    margin-top: 35px;
    position: relative;
    z-index: 2;
}
.contact-us form label input:focus{
    outline-color: var(--orange);
}
.contact-us input.submit_btn{
    background-color: var(--dark-orange);
    border: 1px solid var(--dark-orange);
    padding: 12px 40px;
    border-radius: 50px;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    letter-spacing: 0.5px;
}
.contact-us input.submit_btn:hover{
    background-color: var(--white);
    border: 1px solid var(--white);
    color: var(--dark-orange);
    transition: all 0.3s ease-in-out;
}
.social-icons li img{
    transition: all 0.3s ease-in-out;
}
.social-icons li:hover img{
    transition: all 0.3s ease-in-out;
    filter: brightness(0) invert(1);
}
ul.footer-links li {
    padding-bottom: 22px;
}
ul.footer-links li a.phone-no-2{
    display: block;
    padding-top: 8px;
}
.footer-block-3 li{
    padding-left: 32px;
}
.footer-block-3 li img{ 
    margin-left: -32px;
    position: absolute;
}
.footer-block-3 li:first-child{
    line-height: 30px;
}
.footer-block-3 li:first-child img{
    padding-top: 6px;
}
.newsletter{
    margin-top: -60px;
    margin-bottom: 85px;
}
.copyright p , .copyright a{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--white);
}
.copyright a{
    color: var(--orange);
}
.newsletter input{
    background-color: var(--white);
    border-radius: 50px 0 0 50px;
    border: 2px solid var(--white);
    padding: 16px 40px;
    max-width: 325px;
    width: 100%;
}
.newsletter input::placeholder{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--dark-grey);
}
.newsletter button{
    background-color: var(--orange);
    border-radius: 0 50px 50px 0;
    border: 2px solid var(--orange);
    padding: 16px 40px;
    color: var(--white);
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}
.newsletter button:hover{
    background-color: transparent;
    border: 2px solid var(--orange);
    color: var(--orange);
    transition: all 0.3s ease-in-out;
}
.copyright p{
    margin-bottom: 0;
}
.copyright a:hover{
    padding-left: 0;
    text-decoration: underline!important;
}
.CircularProgressbar {
    width: 100%;
    vertical-align: middle;
    background-color: var(--white);
    border-radius: 50%;
    padding: 25px;
    box-shadow: inset 0px 4px 34px 5px rgba(0, 0, 0, 0.15);
}
.CircularProgressbar .CircularProgressbar-path {
    stroke: var(--dark-orange)!important;
    stroke-linecap: round!important;
    transition: stroke-dashoffset 0.5s ease 0s;
    stroke-width: 12px;
}
.CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
    stroke-linecap: round;
    stroke-width: 3px;
}
.CircularProgressbar .CircularProgressbar-text {
    fill: var(--white);
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
    position: relative;
}
.CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6;
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
    fill: var(--dark-orange);
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
}
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
}
.progress-bar{
    margin-top:52px;
}
#scroll-container {
    width: 100%;
    box-sizing: content-box;
    display: inline-block;
    white-space: nowrap;
}
#scroll-text {
    text-align: right;
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -moz-animation: my-animation 30s linear infinite;
    -webkit-animation: my-animation 30s linear infinite;
    animation: my-animation 30s linear infinite;
    color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 140px;
    line-height: 210px;
    display: block;
    margin-bottom: 0;
    -webkit-text-stroke: 3px var(--orange);
}
@-moz-keyframes my-animation {
    from { -moz-transform: translateX(-100%); }
    to { -moz-transform: translateX(100%); }
}
@-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(-100%); }
    to { -webkit-transform: translateX(100%); }
}
@keyframes my-animation {
    from {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    to {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
}
#scroll-container-1{
    width: 100%;
    box-sizing: content-box;
    display: inline-block;
    white-space: nowrap;
}
#scroll-text-1 {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: my-animation-1 30s linear infinite;
    -webkit-animation: my-animation-1 30s linear infinite;
    animation: my-animation-1 30s linear infinite;
    color: var(--orange);
    font-style: normal;
    font-weight: 700;
    font-size: 140px;
    line-height: 210px;
    display: block;
    margin-bottom: 0;

}
@-moz-keyframes my-animation-1 {
    from { -moz-transform: translateX(100%); }
    to { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes my-animation-1 {
    from { -webkit-transform: translateX(100%); }
    to { -webkit-transform: translateX(-100%); } 
}
@keyframes my-animation-1 {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
}
}
ul.menu-bar-items{
    position: fixed;
    bottom: 5%;
    top: auto;
    left: 50%;
    width: 100%;
    z-index: 0;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 0px 41px 0px;
    transform: translate(-50%, 0%);
}
.footer-block-2 p a:hover{
    padding-left: 0;
}
.footer-block-2 p a:hover img{
    transform: scale(1.03);
    transition: all 0.3s ease-in-out;
}
.footer-block-2 p a img{
    transition: all 0.3s ease-in-out;
}
.team-slider h4{
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 19px;
    color: var(--black);
    text-transform: uppercase;
    margin-bottom: 16px!important;
    padding: 0 75px;
    text-align: center;
}
.team-slider p{
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: var(--orange);
    text-transform: uppercase;
    padding: 0 75px;
    text-align: center;
}
.team-slider .slick-slider .slick-list{
    padding: 0 20% 0 28px!important;
}
.team-slider{
    padding-top: 60px;
    padding-bottom: 80px;
}
.team-slider .slick-dots li button:before{
    display: none;
}
.team-slider .slick-dots li{
    background-color: transparent;
    border: 3px solid var(--black);
    border-radius: 50%;
    width: 10px;
    height: 10px;
}
.team-slider .slick-dots li.slick-active{
    background-color: var(--orange);
    width: 100px;
    border: 3px solid var(--orange);
    border-radius: 50px;
}
.team-slider ul.slick-dots{
    bottom: -12%;
}
.slick-slide img{
    width: 100%;
}
.contact-left{
    background-color: var(--black);
    min-height: 500px;
    padding: 100px 0;
}
.contact-left img{width: 100%;}
.contact-right{
    background-color: var(--black);
    min-height: 500px;
    padding: 100px 0;
}
.contact-us h2 {
    color: var(--orange);
    font-style: italic;
    letter-spacing: 0.05em;
    font-size: 62px!important;
}
.contact-us h2 img{
    padding-right: 30px;
    max-width: 85px;
    /* bottom: 15%;
    display: block;
    position: absolute; */
    animation: linear infinite alternate;
    animation-name: run;
    animation-duration: 2s;
}
@-webkit-keyframes run {
    0% {
        left: 0;
        transform: translateX(0);
    }
    100% {
        left: 100%;
        transform: translateX(-100%);
    }
}
.contact-us h2 span{
    display: block;
    color: transparent;
    -webkit-text-stroke: 3px var(--white);
    padding-left: 115px;
    padding-top: 12px;
}
.contact-right .Button{
    padding-top: 20px;
}
.contact-us form{
    padding-top: 195px;
}
.contact-us form label{
    display: block;
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: var(--orange);
    text-transform: uppercase;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;

}
.contact-us form label input{
    width: 100%;
    border: 1px solid var(--white);
    background-color: transparent;
    border-radius: 0px;
    padding: 22px 25px;
    max-width: 75%;
    margin-top: 5px;
    color: #fff;
}
.contact-us form label input::placeholder{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: var(--white);
}
.contact-us form label input[text]::placeholder{
    text-transform: uppercase;
}
.services-content p span{
    font-style: normal;
    font-weight: 700;
    font-size: 188px;
    line-height: 188px;
    letter-spacing: -0.015em;
    opacity: 0.3;
    color: var(--white);
    display: block;
    margin-bottom: -21%;
}
.services-content h6{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: var(--white);
    text-transform: uppercase;
}
.services-content h4{
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 34px;
    text-transform: uppercase;
    color: var(--white);
    margin-top: 18px!important;
    margin-bottom: 22px!important;
}
.services-content p{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: var(--white);
    max-width: 600px;
    width: 100%;
}
.all-services{
    display: flex!important;
    align-items: center;
    justify-content: space-between;
}
.newsletter-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.services-gif{
    display: flex;
    justify-content: flex-end;
}
.services-gif img{
    max-width: 100%;
}
.services{
    padding-top: 100px;
}
.nav-slider{
    padding-top: 60px;
}
.nav-slider .nav-tabs .nav-link.active{
    background-color: transparent;
    border: none;
    border-radius: 0;
}
.nav-slider .nav-tabs .nav-link:hover, .nav-slider .nav-tabs .nav-link:focus{
    border: none;
}
.nav-slider .nav-tabs .nav-link{
    transition: all 0.3s ease-in-out;
}
.nav-slider .nav-tabs .nav-link img{
    filter: brightness(0) invert(1);
    transition: all 0.3s ease-in-out;
}
.nav-slider .nav-tabs .nav-link:hover img{
    filter: none;
    transition: all 0.3s ease-in-out;
}
.nav-slider :focus-visible , .nav-slider :focus, .nav-slider :active {
    outline: none;
}
.nav-slider .nav {
    --bs-nav-link-color: none;
    --bs-nav-link-hover-color: none;
    --bs-nav-link-disabled-color: none;
    align-items: center;
    justify-content: center;
    border: none;
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0;
    --bs-nav-tabs-border-width: 0px;
}
.nav-slider .nav .nav-item{
    padding: 0 20px;
}
.social-icons li:first-child{
    padding-left: 0;
}
.social-icons li:last-child{
    padding-right: 0;
}
:focus-visible{
    outline: none;
}
.item img {
    height: 420px;
    width: 100%;
    max-width: 350px;
    object-fit: cover;
    border-radius: 6px;
}
.project-slider{
    padding-top: 60px;
}
.project-slider img{
    max-width: 280px;
    margin: auto;
    border-radius: 10px;
}
.slick-prev:before, .slick-next:before {
    color: white;
    background-color: transparent;
}
.header .menu-toggle{text-align: right;}
.react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 10px;
    padding: 0;
}
.project-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.project .project-details{
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    min-height: 100px;
}
.project a{
    transition: all 0.5s ease-in-out;
}
.project a:hover .project-details{
    min-height: 50%;
    transition: all 0.5s ease-in-out;
}
.project a{position: relative;}
.project a .project-image{
    overflow: hidden;
    width: 100%;
    height: 320px;
}
.project a .project-image img{
    transition: all 2.5s ease-in-out;
}
.project a:hover .project-image img{
    transform: scale(1.2);
    transition: all 2.5s ease-in-out;
}
.project-white-bg .project-details{
    background-color: var(--white);
    color: #000;
    padding: 18px 20px;
    bottom: 0;
    top: auto;
}
.project-details h3{
    font-size: 23px!important;
    text-transform: uppercase;
    line-height: normal!important;
    color: var(--dark-orange);
}
.project-details p{
    font-size: 17px!important;
    line-height: normal!important;
    color: var(--black);
    margin-top: 15px;
    margin-bottom: 0;
}
.project-without-bg .project-details{
    padding: 18px 20px;
    background-color: var(--dark-orange);
    width: 100%;    
    z-index: 1;
}
.project-section h5{color: var(--white);}
.project-content-section .project{
    margin-bottom: 30px;
}
.project-without-bg .project-details h3 , .project-without-bg .project-details p{
    color: #fff;
}
.project-content-section .project.project-without-bg .project-details img{
    filter: brightness(0) invert(1);
}
.posts-section .slick-slide{padding: 0 10px;}
.posts-section{padding-top: 40px;}
.hero-section .slick-vertical .slick-slide {
    margin-top: 5px;
}

@media screen and (max-width: 1599px){
    .main-logo a.logo-link img {
        max-width: 135px;
    }
    .banner-section .slick-slider {
        max-width: 1250px;
    }
    .main-logo a.logo-link {
        height: 120px;
        line-height: 120px;
    }
    .top-banner-head p {
        font-size: 17px;
        line-height: 22px;
        right: -300%;
        max-width: 430px;
    }
    .orange-banner-bg img{
        max-width: 210px;
    }
    ul.menu-bar-items li a:hover, ul.menu-bar-items li a {
        padding: 8px;
    }
    ul.menu-bar-items li img {
        max-width: 26px;
    }
    ul.menu-bar-items {
        padding: 10px 30px;
    }
    .banner-right img {
        height: 500px;
    }
    .Social-media-names{
        left: -8%;
    }
    h2 {
        font-size: 50px!important;
        line-height: 50px!important;
    }
    h1 {
        font-size: 110px!important;
        line-height: 110px!important;
        margin-top: 60px!important;
    }
    .banner-section .Button {
        padding-top: 200px;
    }
    .Social-media-names li a {
        font-size: 16px;
        line-height: 16px;
    }
    h3 {
        font-size: 40px!important;
        line-height: 50px!important;
    }
    p {
        font-size: 16px;
        line-height: 24px;
    }
    .link {
        width: 5.2rem;
        font-weight: 600;
    }
    #scroll-text-1 , #scroll-text{
        font-size: 100px;
        line-height: 150px;
    }
    .all-section , .contact-right , .contact-left{
        padding: 70px 0;
    }
    .services{
        padding-top: 70px;
    }
    .running_text.all-section {
        padding: 30px 0;
    }
    .footer-block-2 p a img{
        max-width: 145px;
    }
    .footer a, .footer li {
        font-size: 16px;
        line-height: 16px;
    }
    .footer-block-3 li:first-child {
        line-height: 23px;
    }
    .footer-block-3 li:first-child img {
        padding-top: 3px;
    }
    .newsletter {
        margin-top: -120px;
    }
    .newsletter input::placeholder{
        font-size: 14px;
        line-height: 14px;
    }
    .contact-us form label input{
        max-width: 100%;
    }
    .team-slider h4 , .team-slider p{
        padding: 0;
    }
    .services-content p span {
        font-size: 140px;
        line-height: 140px;
    }
    .services-content h4 {
        font-size: 30px;
        line-height: 30px;
    }
    .services-content p{
        font-size: 18px;
        line-height: 26px;
    }
    .contact-us h2 img{
        max-width: 75px;;
    }
    .contact-us h2 span {
        padding-left: 78px;
    }
}
@media screen and (max-width: 1499px){
    .contact-us h2 {
        font-size: 54px!important;
    }
    .contact-us form label input {
        padding: 18px 25px;
    }
    .contact-us form {
        padding-top: 180px;
    }
}
@media screen and (max-width: 1399px){
    .banner-right img {
        height: 400px;
    }
    .banner-section .slick-slider {
        max-width: 1100px;
    }
    .services-content p span {
        font-size: 120px;
        line-height: 120px;
    }
    .nav-slider .nav li img{
        max-width: 95px;
    }
    ul.menu-bar-items li a::before{
        margin-left: -10px!important;
    }
}
@media screen and (max-width: 1299px){
    .main-logo a.logo-link {
        max-width: 290px;
    }
    .contact-us h2 {
        font-size: 46px!important;
    }
    .contact-us h2 span {
        padding-top: 6px;
    }
    .contact-us form {
        padding-top: 150px;
    }
    .contact-us form label input {
        padding: 14px 25px;
    }
    h3 {
        font-size: 32px!important;
        line-height: 42px!important;
    }
    .contact-us form label {
        font-size: 16px;
        line-height: 22px;
    }
    .contact-us form label input::placeholder{
        font-size: 14px;
        line-height: 20px;
    }
}
@media screen and (max-width: 1199px){
    .contact-us h2 {
        font-size: 35px!important;
    }
    .contact-us h2 span {
        padding-top: 0px;
    }
    .contact-us form label input {
        padding: 12px 25px;
    }
    .team-slider h4 {
        font-size: 17px;
        line-height: 17px;
    }
    .team-slider .slick-dots li {
        border: 2px solid var(--black);
    }
    .footer {
        padding-top: 100px;
    }
    .item img {
        height: 380px;
    }
}
@media screen and (max-width: 1024px){
    .main-logo a.logo-link img {
        max-width: 90px;
    }
    .main-logo a.logo-link {
        height: 85px;
        line-height: 80px;
    }
    .main-logo a.logo-link {
        max-width: 260px;
    }
    .banner-right img {
        height: 340px;
    }
    .orange-banner-bg img {
        max-width: 150px;
    }
    .top-banner-head p {
        font-size: 15px;
        line-height: 21px;
        right: -400%;
    }
    .banner-section .slick-slider {
        max-width: 750px;
    }
    .Social-media-names {
        left: -12%;
        bottom: 50%;
    }
    h2 {
        font-size: 42px!important;
        line-height: 42px!important;
    }
    h3 {
        font-size: 32px!important;
        line-height: 42px!important;
    }
    h5 {
        font-size: 16px!important;
        line-height: 16px!important;
    }
    .link {
        width: 74px;
    }
    .progress-bar {
        margin-top: 20px;
    }
    .all-section, .contact-right, .contact-left {
        padding: 50px 0;
    }
    .services{
        padding-top: 50px;
    }
    .all-section, .contact-right, .contact-left {
        padding: 48px 0;
    }
    .banner-section .main-title {
        margin-top: 50px!important;
    }
    .contact-us h2 span {
        -webkit-text-stroke: 2px var(--white);
    }
}
@media screen and (max-width: 991px){
    .about-section-right , .project-section-right , .services-section-right{
        margin-top: 20px!important;
    }
    .footer .top-footer-content {
        border-top: none
    }
    .footer {
        padding-top: 50px;
    }
    .footer-block-1 ul, ul.footer-links, .newsletter h4 {  
        text-align: left;
    }
    .footer-block-2.text-center {
        text-align: left!important;
    }
    .footer .col-lg-4:nth-child(2){
        order: -1;
    }
    .footer-block-2 {
        background-color: transparent;
        margin-top: 0;
    }
    .newsletter h4::before{
        display: none;
    }
    .social-icons {
        margin-top: 18px;
    }
    .social-icons li {
        padding: 0 20px;
    }
    ul.footer-links {
        margin-top: 10px;
        margin-bottom: 0;
    }
    .social-icons.justify-content-center {
        justify-content: flex-start!important;
    }
    .footer ul{
        padding-left: 0;
    }
    .newsletter-container { 
        justify-content: flex-start;
    }
    .banner-section .slick-slider {
        max-width: 670px;
    }
    .banner-right img {
        height: 300px;
    }
    .container {
        max-width: 92%!important;
    }
    .Social-media-names {
        left: -14%;
    }
    .Social-media-names li a {
        font-size: 14px;
        line-height: 14px;
    }
    h1 {
        font-size: 95px!important;
        line-height: 95px!important;
        margin-top: 45px!important;
    }
    .services-content p span {
        font-size: 90px;
        line-height: 90px;
    }
    .services-content h4 {
        font-size: 26px;
        line-height: 26px;
    }
    .services-content h6 {
        font-size: 16px;
        line-height: 16px;
    }
    .services-content p {
        font-size: 16px;
        line-height: 23px;
    }
    .team-slider h4 {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 10px!important;
    }
    .team-section h3 {
        max-width: 500px;
    }
    .banner-section .Button {
        padding-top: 155px;
    }
}
@media screen and (max-width: 767px){
    h2 {
        font-size: 34px!important;
        line-height: 34px!important;
    }
    h1 {
        font-size: 80px!important;
        line-height: 80px!important;
    }
    .banner-section .Button {
        padding-top: 140px;
    }
    .team-slider .slick-slider .slick-list {
        padding: 0 25px 0 23%!important;
    }
    .services-content p span {
        margin-bottom: -11%;
    }
    .services-slider {
        margin-top: 30px;
    }
    .contact-left {
        min-height: 280px;
    }
    .header {
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .progress-bar .d-flex{
        flex-wrap: wrap;
    }
    .Social-media-names {
        left: -17%;
    }
    .top-banner-head, .banner-section .main-title {
        padding-left: 50%;
    }
    .top-banner-head p {
        right: -340%;
    }
    .Social-media-names li {
        padding-right: 40px;
    }
    .banner-section .slick-dots li {
        width: 76px;
    }
    .nav-slider .nav li img {
        max-width: 70px;
    }
}
@media screen and (max-width: 700px){
    .Social-media-names {
        left: -19%;
    }
}
@media screen and (max-width: 600px){
    .Social-media-names {
        left: -23%;
    }
}
@media screen and (max-width: 575px){
     .banner-right img {
        height: 220px;
    }
    ul.menu-bar-items{border-radius: 50px 50px 0 0;bottom:0;}
    .banner-section .slick-dots li {
        width: 50px;
    }
    .top-banner-head p {
        right: -245%;
    } 
    h2 {
        font-size: 30px!important;
        line-height: 30px!important;
    }
    h1 {
        font-size: 60px!important;
        line-height: 60px!important;
    }
    .banner-section .Button {
        padding-top: 120px;
    }
    .link {
        width: 70px;
    }
    #scroll-text-1, #scroll-text {
        font-size: 80px;
        line-height: 130px;
    }
    .hero-section{
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .main-logo a.logo-link {
        max-width: 200px;
    }
    .Social-media-names {
        bottom: 30%;
    }
    .top-banner-head, .banner-section .main-title {
        padding-left: 40%;
    }
    .Social-media-names {
        left: -24%;
    }
    .nav-slider {
        padding-top: 20px;
    }
    .contact-us h2{
        font-size: 23px!important;
    }
    .contact-us h2 img {
        max-width: 50px;
        padding-right: 25px;
    }
    .contact-us h2 span {
        padding-left: 56px;
    }
    .contact-us h2 span {
        padding-left: 56px;
    }
    .contact-us form label {
        font-size: 16px;
    }
    .contact-right .Button {
        padding-top: 0px;
    }
    .newsletter input::placeholder{
        font-size: 10px;
    }
    .newsletter button {
        padding: 13px 15px;
        font-size: 12px;
    }
    .newsletter input{
        padding: 10px 15px;
        max-width: 100%;
    }
}
@media screen and (max-width: 520px){
    .Social-media-names {
        left: -28%;
    }
}
@media screen and (max-width: 480px){
    .banner-right img {
        height: 180px;
    }
    .top-banner-head p {
        right: -225%;
        padding-right: 30px;
        font-size: 14px;
        line-height: 20px;
    }
    .nav-slider .nav .nav-item {
        padding: 0 10px;
    }
    .nav-slider .nav li img {
        max-width: 46px;
    }
    .services-slider {
        margin-top: 20px;
    }
    .services-content p span {
        font-size: 70px;
        line-height: 70px;
    }
    .services-content h4 {
        font-size: 20px;
        line-height: 20px;
    }
    .services-content h6 {
        font-size: 13px;
        line-height: 13px;
    }
    .main-logo a.logo-link img {
        margin-right: 32px;
    }
}
@media screen and (max-width: 440px){
    .Social-media-names {
        left: -36%;
    }
    .top-banner-head, .banner-section .main-title {
        padding-left: 25%;
    }
    .top-banner-head p {
        right: -203%;
        padding-left: 35%;
        padding-right: 58px;
        margin-top: -15px;
    }
    .Social-media-names {
        left: -39%;
    }
}
@media screen and (max-width: 380px){
    .Social-media-names {
        left: -32%;
        bottom: 25%;
    }
    .top-banner-head p {
        padding-right: 115px;
        margin-top: -12px;
        font-size: 12.5px;
        line-height: 20px;
    }
    .Social-media-names li {
        padding-right: 20px;
    }
    .Social-media-names li a {
        font-size: 13px;
        line-height: 13px;
    }
    h1 {
        font-size: 55px!important;
        line-height: 55px!important;
    }
}
@media screen and (max-width: 340px){
    .Social-media-names {
        left: -38%;
        bottom: 25%;
    }
}

.slider___area{width:100%;}


/** Menu-bar Css Starts Here **/
/* Position and sizing of burger button */
.bm-burger-button {
    width: 30px;
    height: 24px;
    position: relative;
    margin-left: auto;
}
.bm-burger-bars {
    background: var(--white);
}
.bm-burger-bars-hover {
    background: var(--dark-orange);
}
.bm-burger-bars-hover span:first-child{
    transform: rotate(45deg);
}
.bm-burger-bars-hover span:nth-child(2){
    display: none;
}
.bm-burger-bars-hover span:last-child{
    transform: rotate(-45deg);
}
.bm-cross {
    background: #bdc3c7;
    height: 18px!important;
}
.bm-menu-wrap {
    height: 100vh!important;
    left: 0;
    top: 0;
    width: 300px!important;
    box-shadow: rgba(225, 225, 225, 0.35) 0px 5px 15px;
}
.bm-menu {
    background: #fff;
    padding: 3em 1.2em 0;
    font-size: 1.15em;
}
.bm-morph-shape {
    fill: var(--black);
}
.bm-item-list {
    color: var(--black);
    padding: 0.8em;
}
.bm-item {
    display: inline-block;
}
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
}
.bm-item {
    display: inline-block;
    color: #000;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}
.bm-item:hover {
    color: #ffffff;
}
#myLinks ul{padding-left: 0;}
#myLinks ul li{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 32px;
}
.social-icons li {
    padding: 0 20px;
}
#myLinks ul li img{
    width: 100%;
    max-width: 26px;
    margin-right: 14px;
    filter: grayscale(100%);
}
#myLinks ul li a{
    color: var(--dark-orange);
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}
#myLinks ul li a:hover{
    color: var(--black);
    transition: all 0.3s ease-in-out;
}
#myLinks .bm-item{text-align: center; margin-bottom: 20px;}
#myLinks .bm-item a.logo-link img{max-width: 132px;}
#myLinks .social-icons{margin-top: 45px;}
#myLinks .social-icons li a img{
    transition: all 0.3s ease-in-out;
}
#myLinks .social-icons li a:hover img{
    filter: none;
    transition: all 0.3s ease-in-out;
}
/** Menu-bar Css Ends Here **/

@media screen and (max-width: 700px){
    .bm-menu-wrap{width: 340px;}
}


/** WORD_MOVING **/
.word_moving .carousel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: relative;
  -webkit-user-select: none;
  background-color: var(--white);
  margin-top: 175px;
}
.word_moving .slide {
  /* position: absolute; */
  width: 400px;
  height: 200px;
  left: 50%;
  top: 50%;
  position: relative;
  margin-left: -175px;
  margin-top: -150px;
}
.word_moving .slide .img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.word_moving .slide .text {
  position: absolute;
  right: auto;
  left: 0;
  top: 0;
  font-size: 50px;
  font-style: italic;
  font-weight: 700;
  white-space: nowrap;
  color: var(--dark-orange);
  text-transform: uppercase;
}
.word_moving .slide .action {
  position: absolute;
  bottom: 10%;
  left: 80%;
  font-size: 50px;
  font-style: italic;
  font-weight: 700;
  white-space: nowrap;
  color: var(--black);
  text-transform: uppercase;
  cursor: pointer;
}
.word_moving .slide .action .blip {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  top: 3px;
  border-radius: 70px;
  margin-right: 8px;
  vertical-align: middle;
  background: var(--black);
}
.word_moving .slide .action .action-text {
  color: var(--black);
  font-size: 32px;
  font-weight: 600;
}

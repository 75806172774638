body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

h1{
  font-family: 'Fraunces', serif;
}

p , a , div, span , svg{
  font-family: 'DM Sans', sans-serif;
}

h2, h3, h4, h5, h6{
  font-family: 'Arimo', sans-serif;
}